.queries {
  & .query-listing {
    max-height: 100vh;
    overflow-y: auto;
    border-right: 1px solid #ebebeb;
    &::-webkit-scrollbar {
      width: 0 !important;
    }
    @media screen and (max-width: $screen-sm-max) {
      border: none;
      padding: 0 2px;
    }
    & .query-list-title-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    & .query-list-item {
      padding: 25px 20px 23px;
      border-bottom: 1px solid #ebebeb;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      &.active {
        background-color: #f1f7fd;
        transition: all 0.3s ease-in-out;
      }
      &:hover,
      &:focus {
        background-color: lighten(#f1f7fd, 1%);
        transition: all 0.3s ease-in-out;
      }
    }
  }

  & .query-user-name {
    font-weight: 500;
    font-size: 15px;
    line-height: 26px;
  }

  & .query-user-subtitle {
    font-size: 13px;
    line-height: 22px;
    // margin-top: 2px;
    color: #5f5f5f;
  }

  & .query-user-img {
    margin-right: 12px;
  }

  & .queries-select {
    padding-bottom: 24px;
    padding-left: 20px;
    padding-top: 10px;
    border-bottom: 1px solid #ebebeb;
    & > .ant-select {
      width: 90%;
      @media screen and (max-width: $screen-sm-max) {
        width: 100%;
      }
    }
    @media screen and (max-width: $screen-sm-max) {
      padding-left: 0;
    }
  }

  & .query-header-section {
    display: flex;
    align-items: center;
  }

  & .query-details-section {
    & .query-details-title {
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 8px;
      line-height: 28px;
      margin-top: 30px;
    }
    & .query-value {
      display: flex;
      flex-direction: column;
    }
    & .query-details-text {
      font-size: 13px;
      line-height: 24px;
      white-space: break-spaces;
      &.response {
        background-color: #f1f7fd;
        padding: 20px;
      }
    }
    & .query-questions {
      margin-bottom: 20px;
    }
  }
  & .header-tags {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media screen and (max-width: $screen-sm-max) {
      margin-top: 20px;
    }
  }
}

.discard-modal {
  & .reject-desc {
    font-size: 15px;
    color: #434343;
    line-height: 24px;
    margin-bottom: 84px;
  }
  & .btn-cancel {
    text-decoration: underline;
    font-weight: 700;
    font-size: 15px;
    line-height: 175%;
    margin-right: 8px;
  }
}
