.payments {
  &.container-title-section {
    padding: 0px !important;
  }
  .payment-title {
    font-weight: 700;
    font-size: 33px !important;
    @media screen and (max-width: $screen-sm-max) {
      display: none !important;
    }
  }
}

.payments-page {
  & .tabs-container {
    margin-top: 32px;
  }
  & .tab {
    border-radius: 22px;
    cursor: pointer;
    padding: 6px 20px !important;
    line-height: 175%;
  }
  & .detail-tab {
    background: #ece8e6;
    font-weight: 500;
    font-size: 16px;
  }
  & .active-tab {
    background: #000;
    color: #ffffff;
    font-weight: 700;
    font-size: 16px;
  }
}
.payments-empty-state {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-top: 50px;
  @media screen and (max-width: $screen-sm-max) {
    padding-bottom: 60px;
  }
  & .es-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  }
  & .es-subtitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    margin-top: 12px;
  }
  & .es-cta {
    margin-top: 32px;
    height: 48px;
    padding: 10px 40px;
  }
}
.payments-nav {
  margin-top: 12px;

  & .last-balance-card {
    border-radius: 12px;
    background: #dbd1fc;
  }
  & .balance-cards {
    padding: 32px 20px 20px 20px;
    border-radius: 12px;
    @media screen and (max-width: $screen-sm-max) {
      margin-bottom: 16px;
    }
  }
  & .monthly-details {
    padding: 0px 12px 0px 12px !important;
    & .show-diff {
      background-color: #fff;
      border: 1px solid #000000;
      border-radius: 54px;
      position: absolute;
      padding: 2px 12px;
      font-weight: 700;
      font-size: 14px;
      top: 10px;
      right: 10px;
      display: flex;
    }
    & .month-column {
      height: 75%;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      align-items: center;
    }
    & .month-bar {
      background-color: #af97ff;
      width: 56px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }
  & .earning-details {
    padding: 32px 0px 20px 20px !important;
  }
  & .first {
    background: #ebf3fd;
  }
  & .second {
    background: #f4dedc;
  }

  & .icon {
    margin-right: 12px;
    @media screen and (max-width: $screen-sm-max) {
      margin-right: 8px;
      width: 28px;
      height: auto;
    }
  }
  & .balance-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 21px;
    white-space: nowrap;
  }
  & .balance-value {
    margin-top: 4px;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
  }
  & .balance-amount {
    display: flex;
    align-items: center;
  }
  & .info-icon {
    margin-left: 4px;
  }
  & .withdraw-btn {
    border-radius: 4px;
    padding: 6px 18px;
    color: #fff;
    background: $text-color;
    font-size: 14px;
    font-weight: 700;
    height: 32px;
    line-height: 50%;
  }
  & .in-withdrawal {
    background: rgba(232, 127, 49, 0.13);
    border-radius: 24px;
    padding: 7px 14px;
    @media screen and (max-width: $screen-sm-max) {
      font-size: 10px;
    }
  }
  & .balance-payment-options {
    padding: 24px 16px 24px 24px;
    @media screen and (max-width: $screen-sm-max) {
      padding: 16px 10px 12px 16px;
      &.stripe_added,
      &.razorpay {
        padding-top: 20px;
      }
    }

    & .bpo-title {
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
    }
    & .bpo-subtitle {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      margin-top: 4px;
    }
    & .bpo-hidden-text {
      display: none;
      @media screen and (max-width: $screen-sm-max) {
        display: inline;
      }
    }

    & .bpo-stripe-cta {
      height: 32px;
      border-radius: 16px;
      padding: 6px 14px;
      font-weight: 700;
      border: 1px solid $text-color;
    }
  }

  & .stripe-text {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 15px;
    line-height: 21px;
    font-style: italic;
  }

  & .stripe-img {
    margin-right: 13px;
  }

  & .stripe-divider {
    height: 100%;
  }
}
.bpo-status-cta {
  border: 1px solid #000;
  border-radius: 50%;
  padding: 7px;
  display: flex;
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.withdraw-success-content {
  & .withdraw-success-lottie {
    width: 134px;
    height: 134px;
  }
  & .title {
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 175%;
  }
  & .text {
    margin-bottom: 32px;
    text-align: center;
  }
}

.payment-onboarding-modal {
  & .po-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 26px;
  }
  & .po-subtitle {
    font-size: 14px;
    line-height: 21px;
    color: #686b60;
    margin-top: 12px;
  }
  & .po-content {
    font-size: 16px;
    line-height: 24px;
  }
  & .payout-preference-form {
    margin-top: 24px;
  }
  & .po-options-cta {
    padding: 20px 16px 20px 16px;
    height: 74px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    transition: all 0.3s ease;
    margin-bottom: 8px;
    &:disabled,
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
      background: #f1f1f1;
    }
    &:hover,
    &:focus {
      transition: all 0.3s ease;
      background-color: #f7f7f7;
    }
    &:first-of-type {
      margin-top: 30px;
    }
  }
}
.bpo-payment-gateway {
  @media screen and (min-width: $screen-sm-max) {
    margin-right: 60px;
    border: 1px solid #e3e8ed;
    border-top: none;
    border-bottom: none;
    padding: 24px 32px;
  }
  &.stripe_added {
    border-top: none;
  }
  @media screen and (max-width: $screen-sm-max) {
    border: none;
    padding: 24px 0px;
    &.not-added {
      padding-top: 4px;
    }
  }
}

.transaction-tab {
  & .mobile-view {
    text-align: center;
    margin-top: 32px;
    margin-bottom: 60px;
    & .desc {
      font-weight: 500;
      font-size: 16px;
      line-height: 130%;
      margin-top: 16px;
    }
  }
  & .filters-row {
    margin-top: 32px;
    & .tabs {
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
      font-size: 14px;
      padding-bottom: 12px;
    }
    & .tab-icon {
      height: 20px;
      width: 20px;
      margin-right: 10px;
    }
    & .active-table {
      color: #000000;
      font-weight: 700;
      font-size: 14px;
      padding-bottom: 12px;
      border-bottom: 2px solid #000000;
    }
    .download-icon {
      border-radius: 50%;
      padding: 0px;
      width: 40px;
    }
  }
}
.filter-dropdown {
  & .ant-dropdown-menu {
    overflow: auto;
    max-height: 350px;
  }
}
.month-filter-modal {
  & .filter-tab {
    border: 1px solid #bebebe;
    padding: 6px 12px;
    border-radius: 4px;
    margin-bottom: 16px;
  }
  & .active-tab {
    background: #f7f7f7;
    border: 1px solid #222222;
    border-radius: 4px;
    padding: 6px 12px;
    margin-bottom: 16px;
  }
}
