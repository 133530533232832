.lottie-image {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.modal-container {
  display: block;
  @media screen and (max-width: $screen-xs-max) {
    display: none !important;
  }

  & .ant-modal-content {
    border-radius: 16px;
    padding: 0;
    overflow: hidden;
  }
  & .modal-info {
    display: flex;
    .left-image-container {
      align-items: center;
      background-color: #f7f7f7;
      padding: 24px;
      display: flex;
    }
  }
  & .ant-image-img {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    max-height: 450px;
    max-width: 420px;
    min-height: 400px;
    width: auto;
    height: auto;
    vertical-align: top;
  }
  & .ant-modal-body {
    padding: 0px !important;
  }
}
.info-box {
  position: relative;
  & .info-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  & .upper-content {
    padding: 12px 20px 0px 20px;
    @media screen and (min-width: $screen-md-min) {
      padding: 32px 40px 0px 40px;
    }
  }
  & .profile-form {
    padding: 20px 20px 0px 20px;
    @media screen and (min-width: $screen-md-min) {
      padding: 32px 0px 0px 40px;
    }
    & .profile-img-container {
      margin-bottom: 24px;
    }
    & .profile-img {
      border-radius: 50%;
      width: 100px;
      height: 100px;
    }

    & .img-size {
      color: #7a7a7a;
      font-weight: 400;
      font-size: 12px;
    }
    .service-tip {
      display: flex;
      background-color: #fff;
      border-radius: 6px;
      margin-top: 160px !important;
      padding: 16px;
      border: 1px solid rgba(17, 17, 17, 0.1);

      & .tip-text {
        padding-left: 10px;
        & .update-button {
          margin-top: 14px;
          color: black;
          font-size: 12px;
          font-weight: 700;
        }
      }
    }
  }
  & .title {
    font-size: 32px;
    font-weight: 700;
    display: block;
    @media screen and (max-width: $screen-xs-max) {
      font-size: 24px !important;
    }
  }
  & .desc {
    color: #141414b5;
    display: block;
    font-size: 16px;
    font-weight: 500;
    margin-top: 24px;
  }
  & .link-input {
    border: 1px dashed black;
    display: flex !important;
    margin-top: 36px;

    & .copy-btn {
      background-color: #f4f4f4;
      border-radius: 3px;
      border: none;
      margin: 3px;
    }
  }
  & .btn-group {
    padding: 0px 24px 12px 24px;
    & .btn-item {
      font-size: 16px;
      margin-bottom: 12px;
    }
    & .btn-dark {
      background-color: black !important;
    }
  }
  & .theme-container {
    border: 1px solid #f4f4f4;
    border-radius: 6px;
    padding: 12px;

    & .color-icon {
      background: #f7f7f7;
      border-radius: 3px;
      padding: 12px;
    }
  }
  & .field-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }
  & .btn-container {
    margin-left: 24px;
    @media screen and (max-width: $screen-xs-max) {
      margin: 0px 24px 24px 0px;
    }
  }
}

.modal-image {
  width: 378px;
  height: 482px;
  background-image: url("/images/dashboard/nudges/profileNudge.svg");
  @media screen and (max-width: $screen-xs-max) {
    display: flex;
    width: 100%;
    height: 180px;
    background: #fcf6ed;
    justify-content: center;
  }
  & .nudge-profile {
    align-items: center;
    position: absolute;
    top: 84px;
    left: 121px;
    display: flex;
    flex-direction: column;
  }
  & .user-profile {
    border-radius: 50%;
    width: 134px;
    height: 134px !important;
    @media screen and (max-width: $screen-xs-max) {
      margin-top: 24px;
    }
  }
  & .user-desc {
    font-weight: 600;
    font-size: 15px;
    padding-top: 12px;
    max-width: 130px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: break-spaces;
    overflow: hidden;
    word-wrap: break-word;
  }
}

.profile-modal-info {
  background-color: #7a7a7a;
  .modal-image {
    width: 378px;
    height: 482px;
    background-image: url("/images/dashboard/nudges/profileNudge1.svg");
    background-position: center;
    background-repeat: no-repeat;
    @media screen and (max-width: $screen-xs-max) {
      display: flex;
      width: 100%;
      height: 243px;
      background: #fcf6ed;
      justify-content: center;
    }
    & .nudge-profile {
      align-items: center;
      position: absolute;
      top: 84px;
      left: 121px;
      display: flex;
      flex-direction: column;
    }
    & .user-profile {
      border-radius: 50%;
      width: 134px;
      height: 134px !important;
      @media screen and (max-width: $screen-xs-max) {
        margin-top: 50px;
      }
    }
    & .user-desc {
      font-weight: 600;
      font-size: 15px;
      padding-top: 12px;
      max-width: 130px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: break-spaces;
      overflow: hidden;
      word-wrap: break-word;
    }
  }
}

.referral-text {
  font-weight: 700;
  font-size: 16px;
  margin-top: 32px !important;
}
.upload-icon {
  justify-content: center;
  width: 100px;
  height: 100px;
  background: #f3f3f3;
  border-radius: 50px;
  font-size: 24px;
}
