.booking-page-grid,
.booking-divider-column {
  min-height: 95vh;
  height: 100%;
  width: 100%;
}
.booking-banner {
  margin-bottom: 100px;
  &.package {
    @media screen and (max-width: $screen-sm-max) {
      padding-bottom: 10px !important;
    }
  }
  & .package-contents {
    padding-bottom: 16px;
  }
}

.product-tags {
  border: 0.5px solid #e3e8ed;
  & .tag-icon {
    font-size: 24px;
    margin-right: 12px;
  }
  & .webinar-date {
    @media screen and (max-width: $screen-sm-max) {
      border-bottom: 0.5px solid #e3e8ed;
    }
  }
}

.subscription-msg-card {
  border-radius: 24px;
  background: #f2f8ff;
  padding: 24px 32px 32px 32px;
  margin: 12px 32px 64px !important;
  position: relative;
  & .subscription-title {
    color: #000;
    font-size: 20px;
    font-weight: 700;
    line-height: 150%; /* 30px */
    margin-bottom: 4px;
  }
  & .subscription-msg {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 150%; /* 24px */
  }
  & .star-icon {
    position: absolute;
    top: -10px;
    left: 0px;
  }
}

.subscription-msg-service {
  border-radius: 9px;
  background: #f2f8ff;
  padding: 8px;
  margin: 0px 16px 0px !important;
  position: relative;
  & .subscription-msg {
    color: #141414;
    font-size: 15px;
    font-weight: 400;
    line-height: 130%;
  }
  & .star-icon {
    width: 40px;
    height: 40px;
  }
}

.sessions-container {
  // margin-top: 20px;
  margin-bottom: 12px;
  & .webinar-sessions {
    border-bottom: 0.5px solid #e3e8ed;
    padding: 16px 32px;
  }
}
.session-date {
  color: #141414;
  font-size: 16px;
  font-weight: 700;
  line-height: 150%; /* 24px */
}

.session-time {
  color: #5c5c5c;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%; /* 21px */
}

.doc-tag {
  font-size: 16px;
  line-height: 28px;
  padding: 22px 22px 22px 22px;
  border-right: 0.5px solid #e3e8ed;
  display: flex;
  align-items: center;
  &:nth-child(1) {
    padding-left: 36px;
  }
  @media screen and (max-width: $screen-sm-max) {
    padding: 16px;
    line-height: 22px;
  }
}

.product-container {
  border-radius: 40px 40px 0 0;
  background: #fff;
  margin: 60px auto 0 auto;
  position: relative;
  overflow: hidden;
  @media screen and (min-width: $screen-sm-max) {
    width: 682px;
    min-height: 682px;
  }
  @media screen and (max-width: $screen-sm-max) {
    margin: 0 auto 30px;
    border-radius: 0;
    min-height: 94vh;
  }
  & .expert-details {
    & .header {
      padding: 24px 40px 16px;
      @media screen and (max-width: $screen-sm-max) {
        padding: 10px 20px 10px 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      & .profile-circle {
        display: none;
        @media screen and (max-width: $screen-sm-max) {
          display: block;
        }
      }
    }
    & .username {
      text-transform: capitalize;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 0;
      font-weight: 500;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 14px;
        line-height: 18px;
      }
    }
    & .profile-circle {
      border-radius: 50%;
      float: right;
    }
    .service-flexible-charge {
      & .tag {
        border-radius: 5px;
        padding: 10px;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        // box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.06);
        border-radius: 5px;
        text-transform: uppercase;
        display: inline-flex;
        align-items: center;
        @media screen and (max-width: $screen-sm-max) {
          font-size: 12px;
          line-height: 18px;
          padding: 7px 8px;
        }
      }
    }

    & .product-image {
      background-color: #e2e1ff;
      overflow: hidden;
      & img {
        object-fit: contain;
        object-position: top;
        width: 100%;
        max-height: 200px;
        // aspect-ratio: 2/1;
      }
      @media screen and (max-width: $screen-sm-max) {
        border-radius: 0;
      }
    }
  }
  & .service-title {
    background-color: transparent;
    padding: 12px 40px 24px;
    & .title-tag {
      font-weight: 800;
      font-size: 32px;
      line-height: 42px;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 28px;
        line-height: 36px;
      }
    }
    @media screen and (max-width: $screen-sm-max) {
      border-radius: 0;
      padding: 20px;
    }
  }
  & .title {
    margin-top: 24px;
    margin-bottom: 12px;
    font-size: 19px;
    line-height: 34px;
    font-weight: 600;
    text-transform: capitalize;
  }
  & .desc {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.25px;
    margin-top: 24px;
    padding: 0 36px 44px;
    img {
      max-width: 100% !important;
    }
    @media screen and (max-width: $screen-sm-max) {
      padding: 0 20px 20px;
    }
  }
  & .bottom-bar {
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);
    position: fixed;
    bottom: 0;
    z-index: 99;
    padding: 16px 32px;
    background-color: #fff;
    width: 100%;
    max-width: 682px;
    @media screen and (max-width: $screen-sm-max) {
      position: fixed;
      padding: 12px 14px;
    }
  }
  & .btn-booking-cta {
    font-size: 16px;
    line-height: 24px;
    padding: 0 24px;
    height: 48px;
    font-weight: 700;
  }
  & .service-packages {
    padding: 20px 36px;
    border-bottom: 1px solid #ededed;
    display: flex;
    align-items: center;
    gap: 16px;
    & .service-packages-icon {
      background: #f7f7f7;
      border: 1px solid #e3e8ed;
      border-radius: 5px;
      width: 56px;
      height: 56px;
      font-size: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & .service-packages-count {
      font-size: 18px;
      line-height: 27px;
      font-weight: 700;
    }
    & .service-packages-duration {
      color: #5c5c5c;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.powered-by {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 14px;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 80px;
  cursor: pointer;
  @media screen and (max-width: $screen-sm-max) {
    margin-bottom: 120px;
  }
}
