$sidebar-size: 288px;
.flex-align-center {
  display: flex;
  align-items: center;
}

.flex-all-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tag-spacing {
  margin-top: 40px;
}

.checklist-popover {
  position: fixed;
  box-shadow: none;
  background-color: transparent;
  position: fixed;
  & .ant-popover-inner {
    box-shadow: none;
    background-color: transparent;
    padding-bottom: 0;
    & .ant-popover-inner-content {
      box-shadow: 24px 24px 24px 24px rgba(0, 0, 0, 0.09);
      border-radius: 24px;
    }
  }
}

.advance-checklist-profile {
  background: #6a57e3 !important;
  border: none !important;
  padding: 10px !important;
}
.advance-checklist-btn {
  background: #6a57e3 !important;
  border: none !important;
  padding: 10px !important;
  position: fixed;
  bottom: 85px;
  right: 12px;
  z-index: 10;
  font-size: 16px;
  height: unset;
  font-weight: 700;
  color: #fff !important;
  @media screen and (max-width: $screen-sm-max) {
    padding: 6px 8px !important;
  }
  @media screen and (min-width: $screen-sm-max) {
    width: 171px;
    height: 48px;
    bottom: 25px;
    right: 102px;
    z-index: 2;
    border-radius: 32px;
    box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.12);
    border: none;
    line-height: 130%;
    text-align: center;
  }
}
.checklist-count {
  background-color: #e44332;
  border-radius: 50%;
  color: #fff;
  border: 2px solid #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 130%;
  width: 26px;
  height: 26px;
  position: absolute;
  top: -13px;
  right: 20px;
  @media screen and (max-width: $screen-sm-max) {
    top: -8px;
    right: -8px;
    width: 18px;
    height: 18px;
    font-size: 12px;
  }
}
.main-layout {
  top: 0;
  height: 100vh;
  position: relative;
  & .sidebar {
    @media screen and (max-width: $screen-sm-max) {
      flex-direction: column !important;
    }
  }
  & .main-container {
    float: right;
    width: calc(100% - $sidebar-size);
    background: #fff;
    @media screen and (max-width: $screen-sm-max) {
      width: 100%;
    }
    & .container-title-section {
      padding: 24px 0 20px 0;
      border-bottom: 1px solid #e3e8ed;
      @media screen and (max-width: $screen-sm-max) {
        padding-top: 0;
        padding-bottom: 0;
      }
      &.sticky {
        position: sticky;
        top: 0;
        background: #fff;
        z-index: 99;
        @media screen and (max-width: $screen-sm-max) {
          top: $mobile-nav-height;
        }
      }
      & .header-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        & .ant-typography {
          margin-bottom: 0;
        }
        &.hidden {
          @media screen and (max-width: $screen-sm-max) {
            display: none;
          }
        }
        @media screen and (max-width: $screen-sm-max) {
          margin-top: 15px;
        }
      }
      & .layout-header {
        line-height: 43px;
        display: flex;
        align-items: center;
        font-weight: 700 !important;
        margin: 0;
        & .back-btn {
          margin-right: 24px;
          width: 28px;
          cursor: pointer;
          @media screen and (max-width: $screen-sm-max) {
            margin-right: 10px;
          }
        }
        @media screen and (max-width: $screen-sm-max) {
          font-size: 18px;
          line-height: 30px;
        }
      }
    }
    & .container-children {
      padding-top: 20px;
      padding-bottom: 100px;
      @media screen and (max-width: $screen-sm-max) {
        border: none;
      }
    }
    & .tab-link-common {
      border-radius: 22px;
      display: inline-flex;
      align-items: center;
      transition: all 0.4s ease-in-out;
      height: 40px;
      padding: 0 14px;
      &:hover,
      &:focus {
        transition: all 0.4s ease-in-out;
        background-color: #f7f7f7;
      }
      @media screen and (max-width: $screen-sm-max) {
        height: 36px;
        padding: 0 16px;
        // margin-bottom: 16px;
      }
    }
    & .tab-link-disabled {
      cursor: not-allowed !important;
      // pointer-events: none !important;
    }
    & .tab-link {
      border: 1px solid $text-color;
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 12px;
        @media screen and (max-width: $screen-sm-max) {
          margin-right: 8px;
        }
      }
      &.active {
        border-width: 2px;
        background-color: #f7f7f7;
        border-color: $text-color;
        transition: all 0.4s ease-in-out;
        pointer-events: none;
        & .tab-title {
          font-weight: 700;
        }
      }
    }
    & .tab-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 20px;
      ::-webkit-scrollbar {
        display: none;
      }
      & .tab-title {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        white-space: nowrap;
      }
    }
    & .tab-cta-link {
      & .tab-title {
        color: #fff;
      }
      & .anticon {
        font-size: 20px;
        color: #fff;
        @media screen and (min-width: $screen-sm-max) {
          margin-right: 12px;
        }
      }
      &.tab-title-cta {
        margin-top: 20px;
        @media screen and (max-width: $screen-sm-max) {
          & .tab-title {
            display: inline-flex;
          }
        }
      }
      @media screen and (max-width: $screen-sm-max) {
        & .tab-title {
          display: none;
        }
      }
      &:focus,
      &:hover {
        transition: all 0.4s ease-in-out;
        background-color: lighten(#000, 2%);
        transform: scale(1.02);
        // color: $text-color;
      }
    }
    @media screen and (max-width: $screen-sm-max) {
      margin-top: $mobile-nav-height;
    }
    & .btn-ai-writer-dashboard {
      background: linear-gradient(128.49deg, #cb5dff 0%, #1d41be 100%);
      box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.25);
      border-radius: 40px;
      font-size: 18px;
      line-height: 32px;
      height: 56px;
      padding: 10px 32px 10px 16px;
      font-weight: 700;
      display: flex;
      cursor: pointer;
      align-items: center;
      gap: 12px;
      color: #fff;
      position: fixed;
      border: none;
      right: 64px;
      bottom: 64px;
      z-index: 5;
      &:hover,
      &:focus {
        border: none;
      }
      @media screen and (max-width: $screen-sm-max) {
        bottom: 84px;
        right: 20px;
      }
    }
  }
}

.stripe-tag {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #f6ce9d;
  height: 40px;
  z-index: 1000;
  padding: 8px;
  & .text {
    font-weight: 500;
    font-size: 14px;
    line-height: 175%;
    & .connect {
      font-weight: 700;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  & .close-stripe {
    width: 21px;
    height: 21px;
    margin-right: 8px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
  }
}
